/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import personalizeMP4 from '../assets/homepage/flow.mp4';
import retainMP4 from '../assets/homepage/demo.mp4';
import analyzeMP4 from '../assets/homepage/dashboard.mp4';
import { SEO } from '../components/SEO';
import { LandingPage as LandingPageContent } from '@churni/common';

export default function LandingPage(props: any) {
  const lpData = props.data ? props.data.prismic.lp : null;

  const pageTitle = lpData
    ? lpData.page_title[0].text
    : 'Churni | Customer Retention & Churn Management Software';
  const pageDescription = lpData
    ? lpData.page_description[0].text
    : 'Reduce customer churn with a better cancellation flow. Analyze churn feedback and implement retention strategies to save customers at scale.';
  const h1 = lpData
    ? lpData.h1[0].text
    : 'Reduce churn with a better cancellation flow';
  const subtitle = lpData
    ? lpData.subtitle[0].text
    : 'Understand why your customers are leaving and save up to 20% of customers before they cancel.';
  const firstSectionTitle = lpData
    ? lpData.first_section_title[0].text
    : 'What if you decreased churn by 20%?';
  const CTABottom = lpData
    ? lpData.bottom_cta[0].text
    : 'Reduce to reduce churn?';

  return (
    <LandingPageContent
      seo={
        <SEO
          title={pageTitle}
          titleTemplate={'%s'}
          description={pageDescription}
        />
      }
      h1={h1}
      subtitle={subtitle}
      firstSectionTitle={firstSectionTitle}
      CTABottom={CTABottom}
      personalizeImg={props.data.personalizeImg.childImageSharp.fixed.src}
      retainImg={props.data.retainImg.childImageSharp.fixed.src}
      analyzeImg={props.data.analyzeImg.childImageSharp.fixed.src}
      heroVideo={{
        personalizePoster:
          props.data.heroVideoPersonalizePoster.childImageSharp.fluid.src,
        retainPoster:
          props.data.heroVideoRetainPoster.childImageSharp.fluid.src,
        analyzePoster:
          props.data.heroVideoAnalyzePoster.childImageSharp.fluid.src,
        personalizeVideo: personalizeMP4,
        retainVideo: retainMP4,
        analyzeVideo: analyzeMP4
      }}
      integrationsLogos={{
        zapier: props.data.zapier.childImageSharp.fixed,
        calendly: props.data.calendly.childImageSharp.fixed,
        drift: props.data.drift.childImageSharp.fixed,
        crisp: props.data.crisp.childImageSharp.fixed,
        intercom: props.data.intercom.childImageSharp.fixed
      }}
    />
  );
}

export const query = graphql`
  query LandingPage($uid: String!) {
    personalizeImg: file(relativePath: { eq: "homepage/flow-4-3.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 800, height: 600, quality: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    retainImg: file(relativePath: { eq: "homepage/demo-4-3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 800, height: 600, quality: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    analyzeImg: file(relativePath: { eq: "homepage/dashboard-4-3.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 800, height: 600, quality: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    prismic {
      lp: landing_page(lang: "en-us", uid: $uid) {
        h1
        subtitle
        first_section_title
        page_title
        page_description
        bottom_cta
      }
    }
    heroVideoPersonalizePoster: file(
      relativePath: { eq: "homepage/flow.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroVideoRetainPoster: file(relativePath: { eq: "homepage/demo.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroVideoAnalyzePoster: file(
      relativePath: { eq: "homepage/dashboard.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zapier: file(relativePath: { eq: "homepage/logo/zapier.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    calendly: file(relativePath: { eq: "homepage/logo/calendly.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    drift: file(relativePath: { eq: "homepage/logo/drift.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    crisp: file(relativePath: { eq: "homepage/logo/crisp.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    intercom: file(relativePath: { eq: "homepage/logo/intercom.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
